import React,{useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';

import DonutChart2 from './../elements/DonutChart2';
import bg2 from './../assets/images/background/bg2.jpg';
import logowhite from './../assets/images/logo-white.png';
import {api} from "../api/apiService";

function ComingSoon() {
    const [email, setEmail] = useState('');
    const [timerDays, setTimerDays] = useState(0);
    const [timerHours, setTimerHours] = useState('00');
    const [timerMinutes, setTimerMinutes] = useState('00');
    const [timerSeconds, setTimerSeconds] = useState('00');
    const interval = useRef(null);

    const startTimer = (countdownDate) => {
        interval.current = setInterval(() => {
            const now = new Date().getTime();
            const distance = countdownDate - now;

            if (distance < 0) {
                clearInterval(interval.current);
            } else {
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                setTimerDays(days);
                setTimerHours(String(hours).padStart(2, '0'));
                setTimerMinutes(String(minutes).padStart(2, '0'));
                setTimerSeconds(String(seconds).padStart(2, '0'));
            }
        }, 1000);
    };

    useEffect(() => {
        const getComingSoonDueDate = async () => {
            try {
                const data = (await api.get('/site-configs')).data;
                console.log('data ---->', data);

                if (data.length > 0) {
                    const date = new Date(data[0].comingSoonDueDate);
                    startTimer(date.getTime());
                }
            } catch (error) {
                console.error("Error fetching the coming soon date", error);
            }
        };

        getComingSoonDueDate();

        return () => {
            clearInterval(interval.current);
        };
    }, []);

    const form = useRef();

    const subscribe = async (e) => {
        e.preventDefault();
        await api.post('/subscribers', { email });
    };

    return (
        <div className="dez-coming-soon style-3">
            <div className="bg-img" style={{ backgroundImage: `url(${bg2})` }}></div>
            <div className="clearfix dez-coming-bx">
                <div className="dez-content">
                    <div className="center-md">
                        <div className="logo top-left m-b30">
                            <Link to={'/'}><img src={logowhite} alt="Logo" /></Link>
                        </div>
                        <h2 className="dez-title mb-2">COMING SOON</h2>
                        <p className="text-white">Our website is coming soon, follow us for updates now!</p>

                        <form className="dzSubscribe" ref={form} onSubmit={subscribe}>
                            <div className="ft-subscribe">
                                <div className="dzSubscribeMsg text-white"></div>
                                <div className="ft-row m-b15">
                                    <input
                                        name="dzEmail"
                                        required="required"
                                        type="email"
                                        placeholder="Enter Email Address"
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <button name="submit" value="Submit" type="submit" className="btn"><i className="fas fa-arrow-right"></i></button>
                                </div>
                                <p className="text-white">And don’t worry, we hate spam too! You can unsubscribe at any time.</p>
                            </div>
                        </form>

                        <div className="countdown-timer countdown-container">
                            <div className="clock">
                                <div className="clock-item clock-days countdown-time-value">
                                    <div className="clock-canvas">
                                        <DonutChart2 value={timerDays} backgroundColor="rgba(255,255,255,1)" backgroundColor2="rgba(255, 255, 255,0.4)" />
                                    </div>
                                    <div className="text">
                                        <p className="val">{timerDays}</p>
                                        <p className="type-days type-time" data-border-color="#fff">Days</p>
                                    </div>
                                </div>
                                <div className="clock-item clock-hours countdown-time-value">
                                    <div className="clock-canvas">
                                        <DonutChart2 value={timerHours} backgroundColor="rgba(255,255,255,1)" backgroundColor2="rgba(255, 255, 255,0.4)" />
                                    </div>
                                    <div className="text">
                                        <p className="val">{timerHours}</p>
                                        <p className="type-hours type-time" data-border-color="#fff">Hours</p>
                                    </div>
                                </div>
                                <div className="clock-item clock-minutes countdown-time-value">
                                    <div className="clock-canvas">
                                        <DonutChart2 value={timerMinutes} backgroundColor="rgba(255,255,255,1)" backgroundColor2="rgba(255, 255, 255,0.4)" />
                                    </div>
                                    <div className="text">
                                        <p className="val">{timerMinutes}</p>
                                        <p className="type-minutes type-time" data-border-color="#fff">Minutes</p>
                                    </div>
                                </div>
                                <div className="clock-item clock-seconds countdown-time-value">
                                    <div className="clock-canvas">
                                        <DonutChart2 value={timerSeconds} backgroundColor="rgba(255,255,255,1)" backgroundColor2="rgba(255, 255, 255,0.4)" />
                                    </div>
                                    <div className="text">
                                        <p className="val">{timerSeconds}</p>
                                        <p className="type-seconds type-time" data-border-color="#fff">Seconds</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;
